<template>
	<div class="dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<bh-loading :show="loading" />
		<a-card>
			<a-tabs class="addOnTab" default-active-key="1">
				<a-tab-pane key="1" tab="Parking Spot">
					<AddonTable :columns="parkingColumns" :data="parking" type="parking" @add="addAddon"
						@edit="editAddon" @copy="copyAddon" @delete="deleteAddon" addText="Add New Parking Spot" />
				</a-tab-pane>
				<a-tab-pane key="2" tab="Locker">
					<AddonTable :columns="lockerColumns" :data="lockers" type="locker" @add="addAddon" @edit="editAddon"
						@copy="copyAddon" @delete="deleteAddon" addText="Add New Locker" />
				</a-tab-pane>
				<a-tab-pane key="3" tab="Bike Rack">
					<AddonTable :columns="bikeColumns" :data="bikeRacks" type="bikeRack" @add="addAddon"
						@edit="editAddon" @copy="copyAddon" @delete="deleteAddon" addText="Add New Bike Rack" />
				</a-tab-pane>
			</a-tabs>
		</a-card>
		<a-modal :visible="addOnModal" :footer="null" :centered="true" @cancel="onModalClose" :width="'75%'">
			<bh-loading :show="loading" />
			<h5>{{ addOnMap[selectedAddOnType] }}</h5>
			<a-form-model style="margin-top:24px" :model="addEditAddOn" ref="addOnModal" v-if="addEditAddOn">
				<a-row :gutter="16">
					<!-- ID Field -->
					<a-col :span="4">
						<a-form-model-item :label="addOnMap[selectedAddOnType] + ' ID'"
							:prop="selectedAddOnType === 'parking' ? 'pID' : selectedAddOnType === 'locker' ? 'lID' : 'bID'">
							<a-input style="width:100%" size="large"
								v-model="addEditAddOn[selectedAddOnType === 'parking' ? 'pID' : selectedAddOnType === 'locker' ? 'lID' : 'bID']"
								:placeholder="`Example value: ${selectedAddOnType.at(0).toUpperCase()}1`"></a-input>
						</a-form-model-item>
					</a-col>

					<!-- Number Field -->
					<a-col :span="5">
						<a-form-model-item :label="addOnMap[selectedAddOnType] + ' #'" :prop="'name'"
							:rules="req('Please enter the ' + addOnMap[selectedAddOnType] + ' Number')">
							<a-input style="width:100%" size="large" v-model="addEditAddOn.name"
								:placeholder="`Example value: ${selectedAddOnType.at(0).toUpperCase()}101`"></a-input>
						</a-form-model-item>
					</a-col>

					<!-- Type Field -->
					<a-col :span="4" v-if="['parking', 'locker'].includes(selectedAddOnType)">
						<a-form-model-item :label="addOnMap[selectedAddOnType] + ' Type'" :prop="'type'"
							:rules="req('Please select a ' + addOnMap[selectedAddOnType] + ' Type')">
							<a-select v-model="addEditAddOn.type" size="large" style="width: 100%"
								option-label-prop="label">
								<a-select-option v-for="(option, index) in typeOptions" :key="index" :value="option"
									:label="option">{{ option }}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>

					<!-- Price Field -->
					<a-col :span="5">
						<a-form-model-item :label="addOnMap[selectedAddOnType] + ' Price'" :prop="'price'"
							:rules="req('Please enter the ' + addOnMap[selectedAddOnType] + ' Price')">
							<a-input-number style="width:100%" size="large" v-model="addEditAddOn.price"
								:formatter="price => `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0"
								placeholder="Example value: 1"></a-input-number>
						</a-form-model-item>
					</a-col>

					<!-- Description Field -->
					<a-col :span="6">
						<a-form-model-item :label="addOnMap[selectedAddOnType] + ' Description'" :prop="'description'">
							<a-input size="large" v-model="addEditAddOn.description"
								:placeholder="addOnMap[selectedAddOnType] + ' Description'"></a-input>
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="16">
					<!-- Tags Field -->
					<a-col :span="12">
						<a-form-model-item label="Tag(s)" prop="tags">
							<a-select v-model="addEditAddOn.tags" mode="tags" size="large" style="width: 100%"
								placeholder="Tags" option-label-prop="label"></a-select>
						</a-form-model-item>
					</a-col>

					<!-- Unit Field -->
					<a-col :span="12">
						<a-form-model-item label="Unit #" prop="unitId">
							<a-select v-model="addEditAddOn.unitId" show-search option-filter-prop="unitNumber"
								:filter-option="filterOption" mode="single" size="large" style="width: 100%"
								placeholder="Unit #" option-label-prop="label"
								:disabled="!allowEdit(addEditAddOn.unitId)"
								:allowClear="allowEdit(addEditAddOn.unitId)">
								<a-select-option v-for="(unit, unitI) in units" :key="unit + unitI" :value="unit.id"
									:label="getUnitName(unit)" :disabled="unit.salesStatus !== 'available'">
									{{ getUnitName(unit) }}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<div class="w-full" style="text-align:right">
							<a-button size="large" class="cancel-button" @click="onModalClose">CANCEL</a-button>
							<a-button class="ml-3" size="large" type="primary" @click="onSubmit">
								{{ editingAddOn ? 'SAVE' : 'ADD' }}
							</a-button>
						</div>
					</a-col>
				</a-row>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
	import AddonTable from "@/components/condounits/addonTable";
	import bhLoading from "bh-mod/components/common/Loading";
	import { mapState } from "vuex";

	export default {
		components: { bhLoading, AddonTable },
		computed: {
			...mapState({
				allSettings: (state) => state.condoUnits?.allSettings || {},
				units: (state) => Object.values(state.condoUnits?.units || []),
			}),
			settings() {
				return this.allSettings?.app?.options || {};
			},
			addOns() {
				return this.allSettings?.app?.options?.addons || { parking: [], lockers: [], bikeRacks: [] };
			},
			parking() {
				return this.addOns.parking;
			},
			lockers() {
				return this.addOns.lockers;
			},
			bikeRacks() {
				return this.addOns.bikeRacks;
			},

			typeOptions() {
				if (this.selectedAddOnType === 'parking') {
					return ['Regular', 'EV', 'Tandem', 'Extra Large']
				} else if (this.selectedAddOnType === 'locker') {
					return ['Regular', 'Large']
				}
				return []
			},
		},
		data() {
			return {
				loading: false,
				editingAddOn: false,
				addOnModal: false,
				addEditAddOn: null,
				selectedAddOnType: null,
				parkingColumns: this.createColumns("Parking"),
				lockerColumns: this.createColumns("Locker"),
				bikeColumns: this.createColumns("Bike Rack"),
				addOnMap: {
					parking: 'Parking Spot',
					locker: 'Locker',
					bikeRack: 'Bike Rack'
				},
				addOnTypeMap: {
					parking: 'parking',
					locker: 'lockers',
					bikeRack: 'bikeRacks'
				},
				maxAddOnMap: {
					parking: 'maxParking',
					locker: 'maxLocker',
					bikeRack: 'maxBikeRack'
				},
				statusObj: {
					available: "Available",
					sold: "Sold",
					hold: "Hold",
					conditional: "Sold Conditional",
					inventory: "Standing Inventory",
					notreleased: "Not Released",
					allocated: "Allocated",
					approved_for_reservation: "Approved",
					reserved: "Reserved"
				}
			};
		},
		methods: {
			req: msg => ({ required: true, message: msg }),

			createColumns(type) {
				let idKey = `${type.charAt(0).toLowerCase()}ID`
				return [
					{ dataIndex: idKey, key: "id", title: `${type} ID`, sorter: (a, b) => this.sortById(a, b, idKey) },
					{ dataIndex: "name", key: "name", title: `${type} #`, sorter: this.sortByName },
					...(type !== "Bike Rack" ? [{ dataIndex: "type", key: "type", title: `${type} Type`, sorter: this.sortByType }] : []),
					{ dataIndex: "price", key: "price", title: `${type} Price`, sorter: (a, b) => a.price - b.price },
					{ dataIndex: "description", key: "description", title: `${type} Description`, sorter: this.sortByDescription },
					{ title: "Status", key: "status", scopedSlots: { customRender: "status" }, sorter: this.sortByStatus },
					{ title: "Unit #", key: "unitId", scopedSlots: { customRender: "unitId" }, sorter: (a, b) => this.sortByUnit(a, b) },
					{ title: "Tag(s)", key: "tags", scopedSlots: { customRender: "tags" } },
					{ title: "Actions", key: "action", scopedSlots: { customRender: "action" } },
				];
			},
			sortById(a, b, idKey) {
				return isNaN(a[idKey]) || isNaN(b[idKey]) ? a[idKey].localeCompare(b[idKey]) : a[idKey] - b[idKey];
			},
			sortByName(a, b) {
				return a.name.localeCompare(b.name, undefined, { numeric: true });
			},
			sortByType(a, b) {
				return a.type.localeCompare(b.type);
			},
			sortByDescription(a, b) {
				return a.description.localeCompare(b.description);
			},
			sortByStatus(a, b) {
				return a.status.localeCompare(b.status);
			},
			sortByUnit(a, b) {
				const unitA = this.unitName(a);
				const unitB = this.unitName(b);
				return (Number(unitA) || 0) - (Number(unitB) || 0);
			},
			unitName(obj) {
				return this.units.find((x) => x.id === obj.unitId)?.unitNumber || "";
			},

			addAddon(type) {
				this.selectedAddOnType = type;
				this.editingAddOn = false;
				this.selectedAddOn = null;
				this.addEditAddOn = {
					id: '',
					name: '',
					price: 0,
					unitId: '',
					description: '',
					type: '',
					status: 'available',
					tags: []
				}
				if (type === 'parking') {
					this.$set(this.addEditAddOn, 'pID', '')
				} else if (type === 'locker') {
					this.$set(this.addEditAddOn, 'lID', '')
				} else if (type === 'bikeRack') {
					this.$set(this.addEditAddOn, 'bID', '')
				}
				this.$nextTick(() => {
					this.addOnModal = true;
				})
			},
			editAddon(obj, type) {
				this.selectedAddOnType = type;
				this.editingAddOn = true;
				this.addEditAddOn = { ...obj }
				this.selectedAddOn = { ...obj };
				this.addOnModal = true;
			},

			onSubmit() {
				this.$refs.addOnModal.validate(async (valid) => {
					if (valid) {
						let sendObj = { options: {}, packages: [] }
						let addOn = JSON.parse(JSON.stringify(this.addEditAddOn))
						sendObj.options = JSON.parse(JSON.stringify(this.settings))

						if (!this.editingAddOn) {
							addOn.id = Date.now()
						}

						if (!sendObj.options.hasOwnProperty("addons") || (!sendObj.options.addons.parking && !sendObj.options.addons.lockers && !sendObj.options.addons.bikeRacks)) {
							sendObj.options.addons = { parking: [], lockers: [], bikeRacks: [] }
						}

						let addOnType = this.addOnTypeMap[this.selectedAddOnType];
						let maxAddon = this.maxAddOnMap[this.selectedAddOnType];

						let foundAddOn = sendObj.options.addons[addOnType].findIndex(x => x.id == addOn.id)
						if (foundAddOn != -1) {
							if (!this.editingAddOn) return this.$message.error('AddOn with same Id already exists please update the addon details.')
							sendObj.options.addons[addOnType][foundAddOn] = addOn
						}

						if (addOn.unitId) {
							let unit = JSON.parse(JSON.stringify(this.units.find(x => x.id == addOn.unitId)))
							let packages = unit.packages

							if (!packages?.length) {
								packages = [{ other: { addons: { parking: [], lockers: [], bikeRacks: [] } } }]
							}

							if (!packages[0].other.hasOwnProperty("addons") || (!packages[0].other.addons.parking && !packages[0].other.addons.lockers && !packages[0].other.addons.bikeRacks)) {
								packages[0].other.addons = { parking: [], lockers: [], bikeRacks: [] }
							}
							if (!this.editingAddOn) {
								if (packages[0][maxAddon] && packages[0][maxAddon] > packages[0].other.addons[addOnType].length) {
									sendObj.options.addons[addOnType].push(addOn)
								} else {
									return this.$message.error(`The selected unit has reached the maximum number of ${addOnType}.`)
								}
							}

							let foundPackageAddOn = packages[0].other.addons[addOnType].findIndex(x => x.id == addOn.id)
							if (foundPackageAddOn != -1) packages[0].other.addons[addOnType][foundPackageAddOn] = addOn
							else {
								packages[0].other.addons[addOnType].push(addOn)
							}
							sendObj.packages = packages
							unit.packages = packages

							await this.sendUpdateRequest(unit, sendObj, this.editingAddOn ? 'Selected add on updated successfully' : 'add on added successfully');
						} else {
							if (!this.editingAddOn) {
								sendObj.options.addons[addOnType].push(addOn)
							}
							await this.sendUpdateRequest(null, sendObj, this.editingAddOn ? 'Selected add on updated successfully' : 'add on added successfully');
						}

						// Handle a case when user update unit id
						if (this.editingAddOn && this.selectedAddOn.unitId && addOn.unitId !== this.selectedAddOn.unitId) {
							let oldUnit = JSON.parse(JSON.stringify(this.units.find(x => x.id == this.selectedAddOn.unitId)))
							let packages = oldUnit.packages

							let foundPackageAddOn = packages[0].other.addons[addOnType].findIndex(x => x.id == addOn.id)

							if (foundPackageAddOn != -1) packages[0].other.addons[addOnType].splice(foundPackageAddOn, 1)
							else return this.onModalClose();

							sendObj.packages = packages
							oldUnit.packages = packages
							await this.sendUpdateRequest(oldUnit, sendObj);
						}
						this.onModalClose();
					}
				})
			},

			copyAddon(obj, type) {
				let sendObj = { options: JSON.parse(JSON.stringify(this.settings)), packages: [] };
				let newAddon = JSON.parse(JSON.stringify(obj));
				let unit = this.units.find((x) => x.id == newAddon.unitId);
				if (newAddon.unitId && !unit) {
					return this.$message.error('Unit not found. Please refresh the page and try again.');
				}

				if (unit) {
					unit = JSON.parse(JSON.stringify(unit));
					sendObj.packages = unit.packages;
				}

				newAddon.id = Date.now();
				newAddon[`${type.charAt(0)}ID`] = `${newAddon[`${type.charAt(0)}ID`]} (copy)`;

				let addOnTypeKey = this.addOnTypeMap[type];

				// Add the new add on to settings and packages
				if (newAddon.unitId) {
					// Check if the package has space for more addons
					if (
						sendObj.packages[0][`max${type.charAt(0).toUpperCase() + type.slice(1)}`] &&
						sendObj.packages[0][`max${type.charAt(0).toUpperCase() + type.slice(1)}`] >
						sendObj.packages[0].other.addons[addOnTypeKey].length
					) {
						sendObj.options.addons[addOnTypeKey].push(newAddon);
						sendObj.packages[0].other.addons[addOnTypeKey].push(newAddon);
					} else {
						return this.$message.error(`The selected unit has reached the maximum number of ${addOnTypeKey}.`);
					}
				} else {
					sendObj.options.addons[addOnTypeKey].push(newAddon);
				}

				this.sendUpdateRequest(unit, sendObj, `Selected add on copied successfully.`);
			},

			deleteAddon(obj, type) {
				if (this.$p < 20) {
					return this.$message.error("You do not have permission to delete unit add ons");
				}

				this.$confirm({
					title: "Delete Add On",
					content: (h) => <div>Do you want to delete this Add On?</div>,
					okText: "DELETE",
					okType: "danger",
					cancelText: "CANCEL",
					centered: true,
					onOk: () => {
						let sendObj = { options: {}, packages: [] };
						sendObj.options = JSON.parse(JSON.stringify(this.settings));
						let newAddon = JSON.parse(JSON.stringify(obj));
						let unit = null;
						let packages = [];
						if (newAddon.unitId) {
							unit = this.units.find((x) => x.id == newAddon.unitId)
							if (!unit) {
								return this.$message.error('Unit not found. Please refresh the page and try again.')
							}
							unit = JSON.parse(JSON.stringify(unit));
							packages = unit.packages;
						}

						let addOnTypeKey = this.addOnTypeMap[type];

						let foundSetting = sendObj.options.addons[addOnTypeKey].findIndex((x) => x.id == newAddon.id);
						if (foundSetting != -1) {
							sendObj.options.addons[addOnTypeKey].splice(foundSetting, 1);
						}

						if (newAddon.unitId) {
							let foundPackage = packages[0].other.addons[addOnTypeKey].findIndex((x) => x.id == newAddon.id);
							if (foundPackage != -1) {
								packages[0].other.addons[addOnTypeKey].splice(foundPackage, 1);
							}
						}

						sendObj.packages = packages

						this.sendUpdateRequest(unit, sendObj, "Selected add on deleted successfully.");
					},
					onCancel() {
						console.log("Cancel");
					},
				});
			},

			async sendUpdateRequest(unit, sendObj, successMessage) {
				this.loading = true;
				let apiPath = unit ? `/units/:instance/${unit.id}/condounits` : `/settings/:instance/condounits`;

				try {
					await this.$api.put(apiPath, { options: sendObj.options, packages: sendObj.packages })
					this.$store.commit("UPDATE_SETTINGS", { options: sendObj.options });
					if (unit) {
						this.$store.commit("UPDATE_UNIT", unit);
					}
					if (successMessage) {
						this.$message.success(successMessage);
					}
				} catch (err) {
					this.$toastError(err, 'Error while updating add on details. Please try again')
				} finally {
					this.loading = false;
				}
			},

			getUnitName(unit) {
				return `Unit ${unit.unitNumber} ${unit.name ? ' - ' + unit.name : ''} (${this.statusObj[unit.salesStatus] || unit.salesStatus}) ${unit.status == 'draft' ? '(Draft)' : ''}`
			},

			allowEdit(unitId) {
				if (!unitId) return true
				const found = this.units.find(u => u.id === unitId)
				return found?.salesStatus === 'available' || false;
			},

			filterOption(input, option) {
				return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0);
			},

			onModalClose() {
				this.addOnModal = false;
				this.addEditAddOn = null;
				this.selectedAddOn = null;
				this.selectedAddOnType = null;
				this.editingAddOn = false;
			},
		},
	}
</script>

<style>
	.cancel-button.ant-btn {
		border-color: #ece9f1 !important;
		background-color: #ece9f1;
		color: #3f3356;
	}
</style>

<style lang="scss">
	.addOnTab .ant-tabs-nav {
		font-size: 16px;

		.ant-tabs-tab {
			padding-left: 25px;
			padding-right: 25px;
		}
	}

	.addOnTab .ant-tabs-bar {
		margin: 0 0 25px 0;
	}

	.addOnTab .ant-tabs-top-bar {
		border-bottom: none !important;
	}
</style>

<style scoped>
	.addButton {
		cursor: pointer;
		color: var(--orange);
		display: inline-block;
	}
</style>
