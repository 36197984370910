<template>
	<div class="dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<a-card title="Reservation">
			<a-form-model class="mt-3" :model="reservation" ref="newSetup">
				<a-row :gutter="16">
					<a-col :span="12">
						<a-form-model-item prop="widgetURL">
							<template slot="label">
								Reservation Widget URL
								<a-tooltip overlayClassName="change-tooltip-color">
									<template slot="title">
										Please enter your Reservation Widget page URL from your website to redirect users to
										that page from the unit list page when they click on reservation button.
									</template>
									<a-icon type="question-circle"
										style="font-size: 14px; color: black; margin-left: 2px;" />
								</a-tooltip>
							</template>
							<a-input v-model="reservation.widgetURL" placeholder="Reservation Widget URL"
								size="large"></a-input>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>

			<a-button class="pull-right" size="large" type="primary" @click="save" :loading="loading">SAVE</a-button>
		</a-card>
	</div>
</template>

<script>
export default {
	props: {
		value: Number,
	},
	data: () => ({
		loading: false,
		reservation: {
			widgetURL: ''
		},
	}),
	computed: {
		instance() {
			return this.$store.state.instance;
		},
		reservationWidgetURL() {
			return (this.$store.state.condoUnits.allSettings &&
				this.$store.state.condoUnits.allSettings.app &&
				this.$store.state.condoUnits.allSettings.app.options &&
				this.$store.state.condoUnits.allSettings.app.options.reservationWidgetURL) || ''
		},
	},
	created() {
		this.reservation.widgetURL = this.reservationWidgetURL;
		this.$api.get(`/settings/:instance/condounits`).then(({ data }) => {
			if (data) {
				this.$store.commit("UPDATE_SETTINGS", data);
				this.reservation.widgetURL = this.reservationWidgetURL;
			}
		}).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		});
	},

	methods: {
		save() {
			this.loading = true;
			this.$api
				.put(`/settings/:instance/condounits`, {
					options: { reservationWidgetURL: this.reservation.widgetURL },
				})
				.then(({ data }) => {
					this.$store.commit("UPDATE_SETTINGS", data);
					this.$message.success(
						"Reservation widget URL updated successfully."
					);
				})
				.catch((err) => {
					this.$message.success("Error occurred while updating reservation widget url. Please try again!");
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style>
</style>
